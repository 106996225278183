$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

:root {
  --default-font-family: Suisse;

  //font weight global vars
  --font-weight-thin: #{$font-weight-thin};
  --font-weight-light: #{$font-weight-light};
  --font-weight-regular: #{$font-weight-regular};
  --font-weight-medium: #{$font-weight-medium};
  --font-weight-semi-bold: #{$font-weight-semi-bold};
  --font-weight-bold: #{$font-weight-bold};


  //font size global vars
  --font-size-extra-tiny: 8px;
  --font-size-tiny: 10px;
  --font-size-small: 12px;
  --font-size-regular: 14px;
  --font-size-large: 16px;
  --font-size-large-1: 18px;
  --font-size-large-2: 20px;
  --font-size-extra-large: 24px;
  --font-size-extra-large-1: 26px;
  --font-size-extra-large-2: 30px;
  --font-size-extra-large-5: 36px;
  --font-size-extra-large-3: 42px;
  --font-size-extra-large-4: 50px;

  //font line height global vars
  --font-line-height-tiny: 16px;
  --font-line-height-small: 18px;
  --font-line-height-regular: 20px;
  --font-line-height-large: 22px;
  --font-line-height-large-2: 26px;
  --font-line-height-extra-large: 30px;
  --font-line-height-extra-large-2: 32px;
  --font-line-height-extra-large-5: 42px;
  --font-line-height-extra-large-3: 48px;
  --font-line-height-extra-large-4: 50px;
}

@font-face {
  font-family: 'Suisse';
  font-style: normal;
  font-weight: $font-weight-thin;
  src: url('./../assets/fonts/Suisse_Screen_OTF/SuisseScreen-Thin.otf') format('otf'),
    url('./../assets/fonts/Suisse_Screen_TTF/SuisseScreen-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Suisse';
  font-style: italic;
  font-weight: $font-weight-thin;
  src: url('./../assets/fonts/Suisse_Screen_OTF/SuisseScreen-ThinItalic.otf') format('otf'),
    url('./../assets/fonts/Suisse_Screen_TTF/SuisseScreen-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Suisse';
  font-style: normal;
  font-weight: $font-weight-light;
  src: url('./../assets/fonts/Suisse_Screen_OTF/SuisseScreen-Light.otf') format('otf'),
    url('./../assets/fonts/Suisse_Screen_TTF/SuisseScreen-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Suisse';
  font-style: italic;
  font-weight: $font-weight-light;
  src: url('./../assets/fonts/Suisse_Screen_OTF/SuisseScreen-LightItalic.otf') format('otf'),
    url('./../assets/fonts/Suisse_Screen_TTF/SuisseScreen-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Suisse';
  font-style: normal;
  font-weight: $font-weight-regular;
  src: url('./../assets/fonts/Suisse_Screen_OTF/SuisseScreen-Regular.otf') format('otf'),
    url('./../assets/fonts/Suisse_Screen_TTF/SuisseScreen-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Suisse';
  font-style: italic;
  font-weight: $font-weight-regular;
  src: url('./../assets/fonts/Suisse_Screen_OTF/SuisseScreen-RegularItalic.otf') format('otf'),
    url('./../assets/fonts/Suisse_Screen_TTF/SuisseScreen-RegularItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Suisse';
  font-style: normal;
  font-weight: $font-weight-medium;
  src: url('./../assets/fonts/Suisse_Screen_OTF/SuisseScreen-Medium.otf') format('otf'),
    url('./../assets/fonts/Suisse_Screen_TTF/SuisseScreen-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Suisse';
  font-style: italic;
  font-weight: $font-weight-medium;
  src: url('./../assets/fonts/Suisse_Screen_OTF/SuisseScreen-MediumItalic.otf') format('otf'),
    url('./../assets/fonts/Suisse_Screen_TTF/SuisseScreen-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Suisse';
  font-style: normal;
  font-weight: $font-weight-semi-bold;
  src: url('./../assets/fonts/Suisse_Screen_OTF/SuisseScreen-SemiBold.otf') format('otf'),
    url('./../assets/fonts/Suisse_Screen_TTF/SuisseScreen-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Suisse';
  font-style: italic;
  font-weight: $font-weight-semi-bold;
  src: url('./../assets/fonts/Suisse_Screen_OTF/SuisseScreen-SemiBoldItalic.otf') format('otf'),
    url('./../assets/fonts/Suisse_Screen_TTF/SuisseScreen-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Suisse';
  font-style: normal;
  font-weight: bold;
  src: url('./../assets/fonts/Suisse_Screen_OTF/SuisseScreen-Bold.otf') format('otf'),
    url('./../assets/fonts/Suisse_Screen_TTF/SuisseScreen-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Suisse';
  font-style: italic;
  font-weight: bold;
  src: url('./../assets/fonts/Suisse_Screen_OTF/SuisseScreen-BoldItalic.otf') format('otf'),
    url('./../assets/fonts/Suisse_Screen_TTF/SuisseScreen-BoldItalic.ttf') format('truetype');
}
